.nuagike-rte {
  height: 100%;

  .jodit-container {
    padding : 0;
    margin  : 0;
    overflow: auto;
  }

  .jodit-search {
    display: none;
  }
}