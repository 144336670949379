.nuagike-param {
  &.notchecked {
    .nuagike-param-switch {
      label {
        color: var(--bs-grise-3);
      }
    }
  }

  .nuagike-param-switch {
    label {
      color: var(--bs-dark);
    }
  }
}