.nuagike_breadcrumb{
    ol{
        li{

            &.nuagike_breadcrumb_leaf{
                a{
                    color: var(--bs-secondary);
                }
            }
            a{
                display: flex;
                justify-content: center;
                align-items: center;
                width: "50px";
                max-width: "2rem";
                overflow: hidden;
            }

        }
    }
}