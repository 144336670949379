.recharts-pie {
  font-size: 0.8rem;
}

.nuagike-chart-enmpty {
  background-size    : auto 100%;
  background-repeat  : no-repeat;
  background-position: center;
  height             : 12rem;
  background-image   : url("../../../../public/images/illustrations/stats.png");
}