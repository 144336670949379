.nuagike-page {
  opacity         : 1;
  background-color: var(--bs-light-90);
  box-shadow      : rgba(0, 0, 0, 0.16) 0 1px 2px;

  .nuagike-page-row-header {
    background-color: var(--bs-light-50);
    height          : 3rem;

    .nuagike-page-title {
      .btn:hover {
        background: var(--bs-grise-1);
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--bs-grise-3);
  }

  .nuagike-page-col-actions {
    height: 45px;

    span {
      line-height: 2;
    }
  }
}