.nuagike-input {
  box-shadow   : 0 1px 2px var(--bs-info-90) !important;
  border-radius: 10px;

  input,
  select {
    box-shadow: none !important;
  }

  svg {
    color: var(--bs-grise-3);
  }

  &:hover {
    .nuagike-input-copyclipboard button.nuagike-button {
      opacity: 1;
    }
  }

  &.nuagike-input-richtext {
    label {
      opacity    : 0.9;
      font-size  : 0.9em;
      line-height: 1.2rem;
    }
  }

  &.nuagike-input-enum {
    .nuagike-input-enum-item {
      padding: 1px;

      label {
        //color  : var(--bs-primary-30);
        //background: var(--bs-light);
        opacity    : 0.9;
        font-size  : 0.9em;
        line-height: 1.2rem;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .nuagike-input-copyclipboard {
    button.nuagike-button {
      color     : var(--bs-secondary);
      background: none;
      box-shadow: none;
      opacity   : 0;

      &:hover {
        color: var(--bs-dark);
      }

      .nuagike-button-icon {
        background: none;
      }

      &.clipboard-copied {
        opacity                          : 1;
        color                            : var(--bs-success);
        -webkit-animation-timing-function: ease;
        -webkit-animation-fill-mode      : forwards;

        &:before {
          content: "copied";
        }

        &:after {
          content: "";
        }
      }
    }
  }

  .nuagike-input-prependicon {
    height            : calc(3.5rem + 4px);
    border            : 2px solid var(--bs-gray-100);
    border-right-width: 0;
    width             : auto;
    padding           : 0 7px;
    background        : var(--bs-info-90);
    color             : var(--bs-primary-70);

    .fi,
    svg {
      width     : 25px;
      max-height: calc(3.5rem + 4px);
      height    : auto;
      text-align: center;
      margin    : 0 auto;
      color     : inherit;
    }
  }

  label {
    color: var(--bs-info-20);

    &.form-label-prependicon {
      padding-left: 60px;
    }

    svg {
      height        : 10px;
      vertical-align: text-top;
    }
  }

  &.prepended {
    input {
      border-top-left-radius   : 0 !important;
      border-bottom-left-radius: 0 !important;
      border-left              : none !important;
      padding-left             : 5px !important;
    }
  }

  &.nuagike-input-money {
    input {}
  }

  .nuagike-input-prepend {
    font-size                : 0.875rem;
    padding                  : 0 1px 0 5px;
    height                   : 2rem;
    width                    : 45px;
    background               : var(--bs-grise-3);
    border-top-left-radius   : 5px;
    border-bottom-left-radius: 5px;
    border-color             : var(--bs-light);
    color                    : var(--bs-light);

    span {
      width     : 100%;
      text-align: center;
    }
  }

  &.nuagike-input-disabled {
    .form-control,
    .form-select,
    .rbt-input,
    .rbt-token {
      color  : var(--bs-gray-500);
      opacity: 1;
    }

    .rbt {
      opacity     : 1;
      box-shadow  : none !important;
      border-color: var(--bs-gray-100);

      input {
        box-shadow: none;
      }
    }
  }

  .rbt {
    display                    : inline-table;
    -webkit-transition-property: none;
    -moz-transition-property   : none;
    -o-transition-property     : none;
    transition-property        : none;

    .rbt-input {
      margin    : 0;
      padding   : 0;
      min-height: 18px;
      //padding-right: 20px;
      .rbt-input-wrapper {
        //padding   : 0;
        //margin    : 0;
        min-height: 18px;

        .rbt-token {
          padding-right   : 5px;
          min-height      : 18px;
          background-color: var(--bs-success-90) !important;
          color           : var(--bs-dark) !important;

          .rbt-token-label {
            //text-overflow: ellipsis;
          }
        }

        .rbt-token-removeable {
          button {
            margin-top : 2px;
            padding-top: 0;
          }
        }

        input {
          padding: 0;
          margin : 0;
        }
      }
    }

    .rbt-menu {
      &[data-popper-placement='bottom-start'] {
        background: var(--bs-info-90);

        &[aria-selected] {
          &[dropdown-item] {}
        }

        &:hover {}
      }
      z-index           : 2080;
      //position          : relative !important;
      //transform         : none !important;
      //left              : 50%!important;
      //transform         : translateX(-50%);
      //
      //
      //max-height        : 200px !important;
      //max-width         : 400px;
      -webkit-transition: all 0.02s ease !important;
      transition        : all 0.02s ease !important;
    }

    .rbt-menu .dropdown-item {}

    .rbt-input-main {
      border      : none !important;
      padding-left: 0;
    }

    .rbt-input-multi {
      border      : none !important;
      padding-left: 0;
      overflow    : visible;

      .rbt-input-wrapper {
        overflow: visible;
      }
    }

    .rbt-input-multi.focus {
      box-shadow: none !important;
    }

    .rbt-close,
    .rbt-token-remove-button {
      background  : inherit !important;
      padding-left: 5px !important;
      color       : var(--bs-danger) !important;
      border      : none;
      box-shadow  : none;
    }

    .rbt-close span {
      font-size: 1rem;
    }
    ///////////////////
  }

  &.form-check {
    padding: 0;

    label {
      color   : var(--bs-primary);
      overflow: hidden;
    }

    .form-check-input {
      margin : 0;
      padding: 0;
      float  : none;
    }
  }

  .form-control {
    padding-bottom: 0.2rem;

    &[type=readonly] {
      color     : var(--bs-primary);
      background: var(--bs-info-90);
      opacity   : 1;
    }
  }

  &.hidden-label {
    padding-top   : 2px;
    padding-bottom: 2px;
    height        : inherit;

    input {
      padding: 1px 2px;
      height : inherit;
    }

    label {}
  }

  .nuagike-input-file {
    border    : 2px dashed var(--bs-grise-2);
    font-size : 1rem;
    background: var(--bs-grise-1) !important;
    color     : var(--bs-grise-3);
    height    : auto;

    .dropzone {
      width  : 100%;
      margin : 0;
      padding: 0;
      height : 3rem;
    }

    &:hover {
      border: 2px dashed var(--bs-info);
    }

    ul {
      margin     : 0;
      width      : 100%;
      line-height: 1rem;

      li {
        padding: 0;
      }
    }
  }

  &.form-floating > .form-control:focus,
  &.form-floating > .form-control:not(:placeholder-shown) {
    padding-top   : 1.625rem;
    padding-bottom: 0.185rem;
  }
}

.form-check-input:checked {
  background-color: var(--bs-primary);
  border-color    : var(--bs-primary-90);
}

.form-check-input {
  clear  : left;
  margin : 0;
  padding: 0;
}

.form-switch.form-switch-md {
  //  margin-bottom: 1rem;
  /* JUST FOR STYLING PURPOSE */
}

.form-switch {
  &.form-switch-success {
    .form-check-input:checked {
      background-color: var(--bs-success);
      border-color    : var(--bs-success-90);
    }
  }

  &.form-switch-danger {
    .form-check-input {
      border-color: var(--bs-danger-90);
    }

    .form-check-input:checked {
      background-color: var(--bs-danger);
      border-color    : var(--bs-danger-90);
    }
  }
}

.form-switch.form-switch-md .form-check-input {
  height       : 1.5rem;
  width        : calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.form-switch.form-switch-lg {
  margin-bottom: 1.5rem;
  /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-lg .form-check-input {
  height       : 2rem;
  width        : calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.form-switch.form-switch-xl {
  margin-bottom: 2rem;
  /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-xl .form-check-input {
  height       : 2.5rem;
  width        : calc(4rem + 0.75rem);
  border-radius: 5rem;
}

.form-switch.form-switch-sm,
.td-sm .form-switch {
  /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-sm .form-check-input,
.td-sm .form-switch .form-check-input {
  height       : 1rem;
  width        : 2rem;
  border-radius: 2rem;
}

.form-floating .form-label[required]:after {
  content: " *";
  color  : var(--bs-danger);
}

.form-floating .hidden-label {
  padding-top   : 4px !important;
  padding-bottom: 4px !important;
  height        : inherit !important;
  line-height   : 1.5rem !important;

  option {}
}