.nuagike-navigation {
  .nav-tabs {
    //border-bottom: none;
    .nav-item {
      .nav-link {
        padding                : 0.5rem 1rem;
        margin-right           : 0.5rem;
        border-top-right-radius: 0.5rem;
        border-top-left-radius : 0.5rem;
        border                 : none;
        font-size              : 1rem;
        font-weight            : bold;
        color                  : var(--bs-primary-70) !important;
        background-color       : var(--bs-light-70);

        &.active,
        &:hover {
          color       : var(--bs-dark) !important;
          border-color: var(--bs-primary-50) !important;

          svg {
            color: var(--bs-info) !important;
          }
        }

        &.active {
          background-color: var(--bs-light) !important;
          color           : var(--bs-primary) !important;
        }
      }
    }
  }
}