.nuagike-public-form-input-wrapper {
  .nuagike-public-form-input-description {
    clear  : both;
    opacity: 0.5;

    p {
      padding: 0;
      margin : 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
      color: var(--bs-primary);
    }
  }

  .nuagike-public-form-input {
    label {
      color    : var(--bs-grise-3);
      font-size: 1rem;
      height   : 100%;
      width    : 100%;

      &[required]:after {
        content    : " *";
        font-size  : 20px;
        font-weight: bolder;
        color      : var(--bs-danger);
      }
    }

    input,
    select {
      background-color: var(--bs-grise-1);
      color           : var(--bs-primary);
      font-size       : 1rem;

      &:focus,
      &:hover {
        background-color: white;
      }
    }

    input:not([value=""]) {
      background-color: white;
    }

    select {
      color: var(--bs-grise-3);

      &[class*="selected"] {
        background-color: white;
        color           : var(--bs-primary);
      }

      option {
        color: var(--bs-grise-3);

        &:not([value=""]) {
          color: var(--bs-primary);
        }
      }
    }
  }

  .nuagike-public-form-typeahead {
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color  : var(--bs-grise-3);
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--bs-grise-1);
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--bs-grise-1);
    }

    label {
      color    : var(--bs-grise-3);
      font-size: 0.9rem;
      height   : auto;
      padding  : 0.3rem 0.7rem 1rem 0.6rem;
      margin   : 0;
      transform: none !important;
      width    : 100%;
      top      : 0;

      &[required]:after {
        content    : " *";
        font-size  : 20px;
        font-weight: bolder;
        color      : var(--bs-danger);
      }
    }

    .form-control.selected {
      background-color: white;
    }

    .form-control {
      background-color: var(--bs-grise-1);
      font-size       : 1rem;
      vertical-align  : middle;
      height          : 100%;
      padding         : 0.1rem 0.3rem;
      border          : none;

      &.rbt-input-multi {
        height          : 100%;
        box-shadow      : none;
        background-color: inherit;

        input {}
      }

      input {
        height          : 100%;
        box-shadow      : none;
        background-color: inherit;
      }

      .rbt-aux .rbt-close {
        display        : flex;
        align-items    : center;
        justify-content: center;
        color          : var(--bs-danger);
        margin         : auto;

        .rbt-close-content {
          display: none;
        }
      }

      .rbt-token {
        margin          : 1px;
        padding         : 0;
        vertical-align  : middle;
        display         : flex;
        align-items     : center;
        justify-content : center;
        background-color: var(--bs-info-90);
        color           : var(--bs-primary);

        .rbt-token-label {
          font-size: 1rem;
        }
      }
    }
  }
}