$form-label-color             : $gray-400 !default;
$input-bg                     : tint-color($light, 80%) !default;
$form-select-bg               : tint-color($light, 80%) !default;
$input-disabled-color         : $gray-400 !default;
$input-disabled-bg            : $gray-100 !default; // $input-disabled-border-color:           $gray-400 !default;
$input-color                  : $gray-900 !default;
$input-border-color           : $gray-100 !default;
$input-border-width           : 2px;
$input-box-shadow             : none !default; // $input-border-radius:                   $border-radius !default;
// $input-border-radius-sm:                $border-radius-sm !default;
// $input-border-radius-lg:                $border-radius-lg !default;
$input-focus-border-color     : $info !default;
$input-focus-bg               : $white !default;
$form-select-focus-bg         : $white !default;
$input-focus-color            : $dark !default; // $input-focus-width:                     $input-btn-focus-width !default;
$input-focus-box-shadow       : none !default;
$input-placeholder-color      : $gray-400 !default;
$input-plaintext-color        : $dark !default; // $input-height-border:                   $input-border-width * 2 !default;
$input-height-border          : $input-border-width * 2 !default;
$input-btn-padding-y          : 0.375rem !default;
$input-btn-padding-x          : 0.75rem !default;
$input-padding-y              : $input-btn-padding-y !default;
$input-padding-x              : $input-btn-padding-x !default;
$form-floating-height         : add(3.5rem, $input-height-border);
$form-floating-line-height    : 1.25;
$form-floating-padding-x      : $input-padding-x;
$form-floating-padding-y      : 1rem;
$form-floating-input-padding-t: 1.625rem;
$form-floating-input-padding-b: 0.4rem;
$form-floating-label-opacity  : 0.65;
$form-floating-label-transform: scale(.85) translateY(-.5rem) translateX(.15rem);
$form-floating-transition     : opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
$border-radius                : 0.5rem;