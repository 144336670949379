.nuagike-title {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0.5rem 0;
    margin : 0;
    color  : var(--bs-grise-2);
  }
}