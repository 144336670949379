.nuagike-modal {
  .modal-content {}

  &.modal-content-100 {
    .modal-content {
      height: 100%;
    }
  }

  .modal-footer {}
}