.nuagike-dropdown {
  button {
    transition: all 0.5s ease;
    opacity   : 0.9;

    svg {}
  }

  ul.dropdown-menu {
    max-width: 20rem;

    &.show {
      li {
        &:active {}

        &:hover {
          .dropdown-item {
            background: var(--bs-light-50);
          }
        }
      }
    }
  }

  button:active,
  button:checked,
  button:focus,
  button:hover {
    opacity: 1;
  }

  a,
  span {
    cursor       : pointer;
    font-size    : 0.9rem;
    font-weight  : 400;
    line-height  : 1.3;
    margin-top   : 0;
    margin-bottom: 0.2rem;

    &:focus,
    &:hover {}
  }

  .dropdown-item {
    svg {}
  }
}

.nuagike-dropdown-small {
  button {
    transition: all 0.2s ease;
  }
}