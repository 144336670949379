.nuagike-container .nuagike-badge,
.nuagike-modal .nuagike-badge {
  opacity    : 0.9;
  font-size  : 0.85em;
  font-weight: normal;
  color      : var(--bs-primary);
  background : var(--bs-light-80);
  white-space: normal;
  line-height: 1.5em;
  min-height : 2.2em;

  &.bg-inherit {
    background: inherit;
  }

  svg {
    color     : inherit;
    background: inherit;
  }

  &.size-normal {
    font-size  : 0.95em;
    font-weight: normal;
  }

  a,
  span {
    &.badge-price-number {
      font-weight: bold;
    }
  }

  &:hover {
    opacity   : 1;
    background: var(--bs-info-90);

    .nuagike-badge-copyclipboard button.nuagike-button {
      opacity: 1;
    }

    a {
      opacity: 1;
    }
  }

  &.nuagike-badge-country {
    .fi {}

    span {
      line-height: 0.8rem;
      font-size  : 0.8rem;
    }
  }

  .nuagike-badge-copyclipboard {
    button.nuagike-button {
      color     : var(--bs-secondary);
      background: none;
      box-shadow: none;
      opacity   : 0;

      &:hover {
        color: var(--bs-dark);
      }

      .nuagike-button-icon {
        background: none;
      }

      &.clipboard-copied {
        opacity                          : 1;
        color                            : var(--bs-green-1);
        -webkit-animation-timing-function: ease;
        -webkit-animation-fill-mode      : forwards;

        &:before {
          content: "";
        }

        &:after {
          content: "";
        }
      }
    }
  }

  &.nuagike-badge-clickable {
    cursor: pointer;

    &:hover {
      opacity        : 1;
      text-decoration: underline;

      a {
        opacity: 1;
      }
    }
  }

  &.nuagike-badge-copy {
    cursor    : pointer;
    background: var(--bs-info);

    &:hover {
      opacity   : 1;
      background: var(--bs-success);

      a {
        opacity: 1;
      }
    }
  }

  &.nuagike-badge-copied {
    background: var(--bs-success) !important;
  }
}

.nuagike-container .nuagike-badge,
.nuagike-container .nuagike-input div,
.nuagike-modal .nuagike-badge,
.nuagike-modal .nuagike-input div {
  &[class*='STATUS-DRAFT'] {
    color     : var(--bs-grise-3);
    background: var(--bs-grise-2);

    svg {
      color: var(--bs-grise-3);
    }
  }

  &[class*='STATUS-SCHEDULED'],
  &[class*='STATUS-SUBMITTED'] {
    color     : var(--bs-primary);
    background: var(--bs-success-90);
  }

  &[class*='STATUS-OPENED'],
  &[class*='STATUS-ACCEPTED'],
  &[class*='STATUS-APPROVED'] {
    color     : var(--bs-success-10);
    background: var(--bs-success-90);
  }

  &[class*='STATUS-SUSPENDED'] {
    color     : var(--bs-grise-3);
    background: var(--bs-warning-70);
  }

  &[class*='STATUS-REPORTED'] {
    background: var(--bs-warning-90);
    color     : var(--bs-warning-10);
  }

  &[class*='STATUS-CLOSED'] {
    color     : var(--bs-grise-3);
    background: var(--bs-danger-90);
  }

  &[class*='STATUS-CANCELED'] {
    color     : var(--bs-danger-10);
    background: var(--bs-danger-90);
  }

  &[class*='STATUS-REFUSED'] {
    color     : var(--bs-danger-10);
    background: var(--bs-danger-90);
  }

  &[class*='STATUS-ABSENCE'] {
    background: var(--bs-warning-90);
    color     : var(--bs-warning-30);
  }

  &.EVENT_TYPE-RACE {
    color     : var(--bs-orange-3);
    background: var(--bs-blue-1);
  }

  &.EVENT_TYPE-FUNDRAISER {
    color     : var(--bs-orange-3);
    background: var(--bs-purple-1);
  }

  &.EVENT_TYPE-CONFERENCE {
    color     : var(--bs-light);
    background: var(--bs-secondary);
  }

  &.EVENT_TYPE-VIRTUAL {
    color     : var(--bs-blue-1);
    background: var(--bs-yellow-3);
  }

  &.EVENT_TYPE-BUSINESS {
    color     : var(--bs-green-3);
    background: var(--bs-dark);
  }

  &.EVENT_TYPE-FESTIVAL {
    color     : var(--bs-light);
    background: var(--bs-orange-1);
  }

  &.EVENT_TYPE-CEREMONY {
    color     : var(--bs-yellow);
    background: var(--bs-purple);
  }

  &.VENUE_TYPE-COMMUNITY_CENTER {
    color     : var(--bs-dark);
    background: var(--bs-orange-3);
  }

  &.VENUE_TYPE-VIRTUAL {
    color     : var(--bs-dark);
    background: var(--bs-yellow-3);
  }

  &.VENUE_TYPE-BAR_PUB_CLUB {
    color     : var(--bs-dark);
    background: var(--bs-purple-3);
  }

  &.VENUE_TYPE-RESTAURANT {
    color     : var(--bs-dark);
    background: var(--bs-blue-3);
  }

  &.VENUE_TYPE-HOTEL {
    color     : var(--bs-dark);
    background: var(--bs-green-3);
  }

  &.VENUE_TYPE-CONFERENCE_CENTER {
    color     : var(--bs-grise-2);
    background: var(--bs-blue-1);
  }

  &.VENUE_TYPE-BUSINESS_CENTER {
    color     : var(--bs-grise-2);
    background: var(--bs-orange-1);
  }

  &.VENUE_TYPE-SPORT_CLUB {
    color     : var(--bs-grise-2);
    background: var(--bs-purple-1);
  }

  &.VENUE_TYPE-ART_GALEERY,
  &.VENUE_TYPE-ART_GALLERY {
    color     : var(--bs-grise-3);
    background: var(--bs-yellow-1);
  }

  &.VENUE_TYPE-ACADEMIC {
    color     : var(--bs-grise-2);
    background: var(--bs-red-1);
  }
  //Ticket types
  &.OPTION_AMOUNT-FREE,
  &.TICKET_AMOUNT-FREE {
    color     : var(--bs-dark);
    background: var(--bs-warning-80);
  }

  &.OPTION_STOCK-ILLIMITED,
  &.TICKET_STOCK-ILLIMITED {
    color     : var(--bs-dark);
    background: var(--bs-warning-80);
  }

  &.TICKET_CATEGORY-TICKET {
    color     : var(--bs-primary-10);
    background: var(--bs-primary-90);
  }

  &.TICKET_CATEGORY-PASS {
    color     : var(--bs-primary);
    background: var(--bs-yellow-2);
  }

  &.TICKET_CATEGORY-PLACE {
    color     : var(--bs-primary);
    background: var(--bs-cyan-2);
  }

  &.TICKET_CATEGORY-DONATION {
    color     : var(--bs-indigo);
    background: var(--bs-yellow);
  }

  &.TICKET_CATEGORY-PRODUCT {
    color     : var(--bs-light);
    background: var(--bs-purple-3);
  }

  &.TICKET_CATEGORY-BOOKING {
    color     : var(--bs-dark);
    background: var(--bs-orange-3);
  }
  //Custom
  &.STOCK-EXHAUSTED {
    color     : var(--bs-grise-1);
    background: var(--bs-danger);
  }

  &.STOCK-AVAILABLE {
    color     : var(--bs-grise-3);
    background: var(--bs-grise-1);
  }
  //document categories
  &.DOCUMENT-CATEGORY-MEDAL {
    color     : var(--bs-blue-1);
    background: var(--bs-blue-2);
  }

  &.DOCUMENT-CATEGORY-MESSAGE {
    color     : var(--bs-purple-1);
    background: var(--bs-orange-3);
  }

  &.DOCUMENT-CATEGORY-SYSTEM {
    color     : var(--bs-red-1);
    background: var(--bs-red-2);
  }

  &.DOCUMENT-CATEGORY-TICKET {
    color     : var(--bs-green-1);
    background: var(--bs-green-2);
  }

  &.DOCUMENT-CATEGORY-INVOICE {
    color     : var(--bs-indigo-1);
    background: var(--bs-indigo-2);
  }
  //Registrations
  &.SUBSCRIPTION_ORIGIN-FORM {
    background: var(--bs-info-80);
    color     : var(--bs-info-10);
  }

  &.SUBSCRIPTION_ORIGIN-IMPORT {
    color     : var(--bs-grise-2);
    background: var(--bs-dark);
  }

  &.SUBSCRIPTION_ORIGIN-ADMIN {
    color     : var(--bs-primary);
    background: var(--bs-info-70);
  }

  &.SUBSCRIPTION_ROLE-ATTENDEE {
    background: var(--bs-green-2);
    color     : var(--bs-primary);
  }

  &.SUBSCRIPTION_ROLE-SPEAKER {
    background: var(--bs-grise-1);
    color     : var(--bs-pink-1);
  }

  &.SUBSCRIPTION_ROLE-GUEST {
    background: var(--bs-yellow-3);
    color     : var(--bs-purple-1);
  }

  &.SUBSCRIPTION_ROLE-COMMITTEE {
    background: var(--bs-orange-1);
    color     : var(--bs-info-90);
  }

  &.SUBSCRIPTION_ROLE-REVIEWER {
    background: var(--bs-green-1);
    color     : var(--bs-info-90);
  }

  &.SUBSCRIPTION_ROLE-APPLICANT {
    background: var(--bs-blue-1);
    color     : var(--bs-info-80);
  }

  &.SUBSCRIPTION_ROLE-EXHIBITOR {
    background: var(--bs-pink-2);
    color     : var(--bs-dark);
  }

  &.SUBSCRIPTION_ROLE-PARTNER {
    background: var(--bs-cyan-2);
    color     : var(--bs-dark);
  }

  &.SUBSCRIPTION_ROLE-SPONSOR {
    background: var(--bs-indigo-1);
    color     : var(--bs-info-80);
  }

  &.SUBSCRIPTION_STATUS-REQUIRE_ADMIN_VALIDATION {
    color     : var(--bs-warning-10);
    background: var(--bs-warning-90);
  }

  &.SUBSCRIPTION_STATUS-VALIDATED {
    color     : var(--bs-success-10);
    background: var(--bs-success-90);
  }

  &.SUBSCRIPTION_STATUS-CANCELED {
    color     : var(--bs-grise-3);
    background: var(--bs-danger-90);
  }

  &.SUBSCRIPTION_STATUS-REJECTED {
    color     : var(--bs-danger-10);
    background: var(--bs-danger-90);
  }
  //	COMPLETED, STARTING, STARTED, STOPPING, STOPPED, FAILED, ABANDONED, UNKNOWN;
  &.BATCH_STATUS-UNKNOWN {
    color     : var(--bs-grise-3);
    background: var(--bs-grise-1);
  }

  &.BATCH_STATUS-COMPLETED {
    background: var(--bs-grise-2);
    color     : var(--bs-green-1);
  }

  &.BATCH_STATUS-STARTED,
  &.BATCH_STATUS-STARTING,
  &.BATCH_STATUS-STOPPED,
  &.BATCH_STATUS-STOPPING {
    color     : var(--bs-yellow-1);
    background: var(--bs-grise-1);
  }

  &.BATCH_STATUS-ABANDONED,
  &.BATCH_STATUS-FAILED {
    color     : var(--bs-danger);
    background: var(--bs-grise-1);
  }

  &.LOGLEVEL-TRACE {
    color     : var(--bs-grise-3);
    background: var(--bs-light);
  }

  &.LOGLEVEL-DEBUG {
    color     : var(--bs-info);
    background: var(--bs-light);
  }

  &.LOGLEVEL-INFO {
    color     : var(--bs-light);
    background: var(--bs-info);
  }

  &.LOGLEVEL-WARN {
    color     : var(--bs-dark);
    background: var(--bs-warning);
  }

  &.LOGLEVEL-ERROR {
    color     : var(--bs-light);
    background: var(--bs-danger);
  }

  &.APPLICATION-evt {
    color     : var(--bs-light);
    background: var(--bs-primary);
  }

  &.APPLICATION-cms {
    color     : var(--bs-yellow-1);
    background: var(--bs-indigo-1);
  }

  &.APPLICATION-doc {
    color     : var(--bs-cyan);
    background: var(--bs-purple);
  }

  &.APPLICATION-mkt {
    color     : var(--bs-yellow);
    background: var(--bs-green);
  }

  &.APPLICATION-tkt {
    color     : var(--bs-blue);
    background: var(--bs-orange);
  }

  &.DOCVARCONTEXT-SUBSCRIPTION {
    color     : var(--bs-indigo-1);
    background: var(--bs-indigo-2);
  }

  &.DOCVARCONTEXT-EVENT {
    color     : var(--bs-blue);
    background: var(--bs-orange-2);
  }

  &.DOCVARCONTEXT-SESSION {
    color     : var(--bs-indigo-1);
    background: var(--bs-yellow-2);
  }

  &.DOCVARCONTEXT-CFP {
    color     : var(--bs-orange);
    background: var(--bs-cyan-2);
  }

  &.REALMTYPE-STATIC {
    color     : var(--bs-gray-000);
    background: var(--bs-gray-800);
  }

  &.REALMTYPE-DYNAMIC {
    color     : var(--bs-indigo);
    background: var(--bs-cyan);
  }

  &.ORDER_STATUS-REGISTERED {
    color     : var(--bs-primary-40);
    background: var(--bs-warning-90);
  }

  &.ORDER_STATUS-RETURNED {
    color     : var(--bs-primary-90);
    background: var(--bs-primary-30);
  }

  &.ORDER_STATUS-DRAFT {
    color     : var(--bs-info-30);
    background: var(--bs-light);
  }

  &.ORDER_STATUS-CONFIRMED {
    color     : var(--bs-success-10);
    background: var(--bs-success-90);
  }

  &.ORDER_STATUS-DELIVERED {
    color     : var(--bs-success-10);
    background: var(--bs-success-90);
  }

  &.ORDER_STATUS-CLOSED {
    color     : var(--bs-primary-50);
    background: var(--bs-primary-80);
  }

  &.ORDER_STATUS-CANCELED {
    color     : var(--bs-red-1);
    background: var(--bs-grise-2);
  }

  &.ORDER_STATUS-EXPIRED {
    color     : var(--bs-warning-10);
    background: var(--bs-warning-90);
  }
  ////
  &.ORDERLINE_TYPE-TICKET {
    color     : var(--bs-light);
    background: var(--bs-primary);
  }

  &.ORDERLINE_TYPE-OPTION {
    color     : var(--bs-light);
    background: var(--bs-secondary);
  }

  &.PAYMENT_ORIGIN-STRIPE {
    color     : var(--bs-purple);
    background: var(--bs-purple-2);
  }

  &.PAYMENT_ORIGIN-ADMIN {
    color     : var(--bs-primary);
    background: var(--bs-info-70);
  }
  //	AWAIT_PAYMENT, PAYMENT_ACCEPTED, PAYMENT_REFUSED, //
  // AWAIT_REFUND, REFUND_ACCEPTED, REFUND_REFUSED //
  &.PAYMENT_STATUS-PAYMENT_ACCEPTED {
    color     : var(--bs-success-10);
    background: var(--bs-success-90);
  }

  &.PAYMENT_STATUS-REFUND_ACCEPTED {
    color     : var(--bs-grise-3);
    background: var(--bs-warning-90);
  }

  &.PAYMENT_STATUS-AWAIT_PAYMENT,
  &.PAYMENT_STATUS-AWAIT_REFUND {
    color     : var(--bs-primary-40);
    background: var(--bs-warning-90);
  }

  &.PAYMENT_STATUS-PAYMENT_REFUSED,
  &.PAYMENT_STATUS-REFUND_REFUSED {
    color     : var(--bs-danger-90);
    background: var(--bs-danger-10);
  }

  &[class*='MEDAL_TYPE-'] {
    color     : var(--bs-light);
    background: var(--bs-dark);
  }

  &[class*='CFP_TYPE-COMMUNICATION'] {
    color     : var(--bs-purple-1);
    background: var(--bs-purple-2);
  }

  &[class*='CFP_TYPE-ABSTRACT'] {
    color     : var(--bs-orange-1);
    background: var(--bs-orange-2);
  }

  &[class*='CFP_TYPE-CONTRIBUTION'] {
    color     : var(--bs-green-1);
    background: var(--bs-green-2);
  }

  &[class*='CFP_TYPE-CONTEST'] {
    color     : var(--bs-pink-1);
    background: var(--bs-light);
  }

  &[class*='PAPER_STATUS-REVIEWED'] {
    color     : var(--bs-info-10);
    background: var(--bs-info-90);
  }

  &[class*='EVALUATION_TYPE-ORDINAL'] {
    color     : var(--bs-light);
    background: var(--bs-purple);
  }

  &[class*='EVALUATION_TYPE-BINARY'] {
    color     : var(--bs-light);
    background: var(--bs-orange);
  }

  &[class*='FORM_FIELD_TYPE-'] {
    color     : var(--bs-info-10);
    background: var(--bs-warning-90);
  }

  &[class*='FORM_FIELD_TYPE-AUTOCOMPLETE'],
  &[class*='FORM_FIELD_TYPE-CHECKBOXES'],
  &[class*='FORM_FIELD_TYPE-DROPDOWN'],
  &[class*='FORM_FIELD_TYPE-RADIOBUTTONS'] {
    color     : var(--bs-green);
    background: var(--bs-info-70);
  }

  &[class*='DATE'][class*='FORM_FIELD_TYPE'],
  &[class*='TIME'][class*='FORM_FIELD_TYPE'] {
    color     : var(--bs-light);
    background: var(--bs-orange-3);
  }

  &[class*='TEXT'][class*='FORM_FIELD_TYPE'] {
    color     : var(--bs-primary);
    background: var(--bs-info-70);
  }

  &[class*='FILE'][class*='FORM_FIELD_TYPE'] {
    color     : var(--bs-light);
    background: var(--bs-indigo-3);
  }

  &[class*='COUNTRY'][class*='FORM_FIELD_TYPE'] {
    color     : var(--bs-light);
    background: var(--bs-blue-3);
  }

  &[class*='EMAIL'][class*='FORM_FIELD_TYPE'] {
    color     : var(--bs-white);
    background: var(--bs-green-3);
  }

  &[class*='FORM_CATEGORY-REGISTRATION'] {
    color     : var(--bs-blue);
    background: var(--bs-primary-90);
  }

  &[class*='FORM_CATEGORY-REGISTRATION_CFP'] {
    color     : var(--bs-purple);
    background: var(--bs-primary-90);
  }

  &[class*='FORM_CATEGORY-REGISTRATION_SURVEY'] {
    color     : var(--bs-white);
    background: var(--bs-orange-3);
  }

  &[class*='USER_AUTHORITY-ADMIN'] {
    color     : var(--bs-white);
    background: var(--bs-red-1);
  }

  &[class*='USER_AUTHORITY-COLLAB'] {
    color     : var(--bs-white);
    background: var(--bs-orange-1);
  }

  &[class*='USER_AUTHORITY-TECH'] {
    color     : var(--bs-white);
    background: var(--bs-grise-3);
  }

  &[class*='USER_AUTHORITY-REVIEWER'] {
    color     : var(--bs-white);
    background: var(--bs-blue-1);
  }

  &[class*='USER_AUTHORITY-STEWARD'] {
    color     : var(--bs-white);
    background: var(--bs-green-1);
  }
}