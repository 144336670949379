.nuagike-table {
  background-color: transparent;
  box-shadow      : rgba(100, 100, 111, 0.2) 0 7px 10px 0;
  //overflow        : hidden;
}

.nuagike-table table {
  color        : var(--bs-light);
  //overflow     : hidden;
  text-overflow: ellipsis;
  table-layout : fixed;
  opacity      : 1;
  border       : 1px solid var(--bs-light);

  &.loading {
    opacity:.8;
  }



  tbody .form-floating {
    padding-top   : 1px;
    padding-bottom: 1px;

    .form-select {
      height      : 1.7rem;
      margin-right: 2px;

      &[disabled] {
        background: inherit;
        border    : none;
        cursor    : none;
      }
    }

    .form-control {
      padding-top   : 1px;
      padding-bottom: 1px;
      height        : 1.7rem;
      line-height   : 1.5rem;
      padding       : 0.2rem 0.1rem;
      border        : 1px var(--bs-grise-1) solid;
      margin-right  : 2px;

      &.is-invalid {
        border-color       : var(--bs-danger) !important;
        padding-right      : calc(1.5em + 0.75rem);
        background-image   : url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e55353'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e55353' stroke='none'/%3e%3c/svg%3e") !important;
        background-repeat  : no-repeat !important;
        background-position: right calc(0.375em + 0.1875rem) center !important;
        background-size    : calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
      }

      &[disabled] {
        background: inherit;
        border    : none;
      }
    }
  }

  .nuagike-dropdown {
    .btn-group {
      button.nuagike-button {
        box-shadow: none;
        opacity   : 0.7;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.nuagike-table table thead {}

.nuagike-table table thead tr {
  border: none;
}

.nuagike-table table thead tr th {
  vertical-align: top;
  padding       : 2px 1px;
  border        : none;
}

.nuagike-table table thead tr th span {
  display           : block;
  padding           : 1px;
  margin            : 0;
  color             : var(--bs-grise-3);
  font-size         : 0.8rem;
  font-weight       : lighter;
  text-align        : left;
  display           : -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow          : hidden;
  border            : none;
}

.nuagike-table table thead tr th span.sorted-asc {
  border-top-color: var(--bs-info);
}

.nuagike-table table thead tr th span.sorted-desc {
  border-bottom-color: var(--bs-info);
}

.nuagike-table table thead tr th {
  button.nuagike-button {
    border    : none;
    box-shadow: none;
    background: transparent !important;
  }

  .nuagike-input {
    border-radius: 0.2rem;
    box-shadow   : none;
    background   : var(--bs-light-70);

    .form-control,
    .form-select {
      font-size     : 0.8em;
      line-height   : 1.4em;
      font-weight   : 700;
      padding-top   : 0;
      padding-bottom: 0;
      background    : transparent;
      opacity       : 0.8;
      border        : none;
      border-radius : 0.2rem;
      box-shadow    : none;
    }
  }
}

.nuagike-table table tbody tr {
  border: none;
  height: 2.5em;

  button {
    //padding: 1px 6px;
  }
}

.nuagike-table table tr .icon-drag {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.nuagike-table table tr .icon-drag:hover {
  color: var(--bs-primary);
}

.nuagike-table table tr .icon-drag:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.nuagike-table table tbody tr td {
  background    : white;
  vertical-align: middle;
  line-height   : 2rem;
  height        : 2rem;
  padding       : 1px 2px;
  margin        : 0 1px;
  border-left   : none;
  border-bottom : 1px solid white;
  border-right  : none;
  border-top    : 1px var(--bs-light) solid;
}

.nuagike-table table tbody tr td a,
.nuagike-table table tbody tr td span {
  overflow: hidden;

  &.default-span {
    font-size  : 0.95em;
    font-weight: 550;
  }
}

.nuagike-table table tbody tr[rowref='new'] td {
  border      : 2px var(--bs-success) dashed;
  border-left : none;
  border-right: none;
  background  : var(--bs-light);
}

.nuagike-table table tbody tr[rowref='new'] td input[type='text'],
.nuagike-table table tbody tr[rowref='new'] td select {
  background: white !important;
}

.nuagike-table table tbody tr td .form-control-hidden-border {
  background: none;
  border    : none;
}

.nuagike-table table tbody tr td .form-control:hover {
  background: white;
}

.nuagike-table table tbody tr td .new-input {
  border: 1px var(--bs-light) dotted !important;
}

.nuagike-table table tbody tr td span {
  display           : -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.nuagike-table .pagination {
  z-index        : 1;
  display        : flex;
  justify-content: space-between;
  align-items    : stretch;
  flex-wrap      : wrap;
  padding        : 3px;
  box-shadow     : 0 0 15px 0 rgb(0 0 0 / 10%);
  border-top     : 2px solid rgba(0, 0, 0, .1);
}

.nuagike-table .pagination .next,
.nuagike-table .pagination .previous {
  flex      : 1 1;
  text-align: center;
}

.nuagike-table .pagination .center {
  flex           : 1.2 1;
  text-align     : center;
  margin-bottom  : 0;
  display        : flex;
  flex-direction : row;
  flex-wrap      : wrap;
  align-items    : center;
  justify-content: space-around;
}

.nuagike-table .pagination button {}

.nuagike-table .pagination {
  button:not([disabled]):hover {
    background: rgba(0, 0, 0, .2);
  }

  button:disabled {
    opacity: 0.2;
  }
}

.nuagike-table .pagination {
  input,
  select {
    height     : 2em;
    line-height: 2em;
    font-size  : 0.8em;
    padding    : 0 0.5rem;
    margin     : 0;
    width      : 4rem;
  }

  select {
    width  : 5rem;
    padding: 0 0.5rem;
  }
}