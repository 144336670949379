.nuagike-dndcontainer {
  cursor: pointer;

  .draggable-item {
    background: var(--bs-info-90);

    &.isDragging {}

    &:active {
      border: var(--bs-info-10);
    }

    .col-drag {
      cursor: move;
      /* fallback if grab cursor is unsupported */
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;

      &:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
      }
    }
  }
}