// Here you can add other styles
// Here you can add other styles
/* Works on Firefox */
:root {
  --toastify-toast-width  : 400px;
  --toastify-color-light  : var(--bs-light-10);
  --toastify-color-dark   : var(--bs-dark);
  --toastify-color-info   : var(--bs-info);
  --toastify-color-success: var(--bs-success);
  --toastify-color-warning: var(--bs-warning);
  --toastify-color-error  : var(--bs-danger);
  --input-border-color    : var(--bs-gray-100);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--bs-secondary) var(--bs-light);
}
/* Works on Chrome, Edge, and Safari */

*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: var(--bs-light);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--bs-secondary);
  border-radius   : 20px;
  border          : 3px solid var(--bs-light);
}

.border-gray {
  border-color: var(--bs-gray-100) !important;
}

.vh-100 {
  min-height: 100vh;
}

a,
input[type='checkbox'],
select,
option,
.cursor-pointer {
  cursor: pointer !important;
}

.btn:active,
.btn:checked,
.btn:focus,
.btn:hover {
  outline   : none !important;
  box-shadow: none !important;
  color     : var(--bs-light);
}

.border-dotted {
  border-style: dotted !important;
}

.nav-item {
  overflow: hidden;
}

.nuagike-rounded-toggle {
  height    : 3rem;
  min-width : 3rem;
  width     : 3rem;
  transition: all 1s ease;
}

.nuagike-rounded-toggle-small {
  padding   : 0;
  height    : 2rem;
  min-width : 2rem;
  width     : 2rem;
  transition: all 1s ease;
}

.text-ellipsis {
  display      : flex;
  white-space  : nowrap;
  overflow     : hidden;
  text-overflow: ellipsis;
  max-width    : 100%;
}

.dropdown-toggle {
  &:after {
    content: none;
  }
}

.modal {
  display: block !important;
  /* I added this to see the modal, you don't need this */
}
/* Important part */

.modal-dialog {
  overflow-y: initial !important;
}

.modal-body {
  overflow-y: auto !important;
}

.card {
  box-shadow: 0 1px 1px 0 rgba(var(--bs-elevation-base-color, 60, 75, 100), .14), 0 2px 1px -1px rgba(var(--bs-elevation-base-color, 60, 75, 100), .12), 0 1px 3px 0 rgba(var(--bs-elevation-base-color, 60, 75, 100), .20);
}

.one-line {
  display           : -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow          : hidden;
}