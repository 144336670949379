@import "bootstrap/scss/functions"; // If you want to override variables do it here
// Override whatever Bootstrap variable you want right here
@import "variables/_colors.scss";
@import "variables/_fonts.scss";
@import "variables/_tables.scss";
@import "variables/_buttons.scss";
@import "variables/_modal.scss";
@import "variables/_forms.scss"; //@import "variables"; // Then have Bootstrap do it's magic with these new values
@import "bootstrap/scss/bootstrap"; // 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
// Required
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins"; //@import "bootstrap/scss/forms";
@import "bootstrap/scss/forms";
$enable-ltr: true;
$enable-rtl: true; // Import CoreUI for React components library
//@import "@coreui/coreui/scss/coreui"; // Import Chart.js custom tooltips styles
//@import "@coreui/chartjs/scss/coreui-chartjs";
//@import "example"; // If you want to add custom CSS you can put it here.
@import 'react-bootstrap-typeahead/css/Typeahead.css';
@import 'react-toastify/dist/ReactToastify.css';
@import 'flag-icons/css/flag-icons.min.css';
@import 'react-datepicker/dist/react-datepicker.css';
@import 'react-big-calendar/lib/css/react-big-calendar.css';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
@import "layout";
@import "custom";