$white: #fff !default;
$dark: #0d1b2a;
$primary: #1b273b;
$secondary: #1955bc;
$info: #778da9;
$light: #ebebeb;
$success: #2eb85c !default;
$warning: #f9b115 !default;
$danger: #e55353 !default;
$gray-base: #3c4b64 !default;
$gray-000: #f3f3f3 !default;
$gray-100: #ebedef !default;
$gray-200: #d8dbe0 !default;
$gray-300: #c4c9d0 !default;
$gray-400: #b1b7c1 !default;
$gray-500: #9da5b1 !default;
$gray-600: #8a93a2 !default;
$gray-700: #768192 !default;
$gray-800: #636f83 !default;
$gray-900: #4f5d73 !default;
$black: #000015 !default; //
//--------------
// scss-docs-start color-variables
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default; //
// scss-docs-end color-variables
$blue-100: tint-color($blue, 80%) !default;
$blue-200: tint-color($blue, 60%) !default;
$blue-300: tint-color($blue, 40%) !default;
$indigo-100: tint-color($indigo, 80%) !default;
$indigo-200: tint-color($indigo, 60%) !default;
$indigo-300: tint-color($indigo, 40%) !default;
$purple-100: tint-color($purple, 80%) !default;
$purple-200: tint-color($purple, 60%) !default;
$purple-300: tint-color($purple, 40%) !default;
$pink-100: tint-color($pink, 80%) !default;
$pink-200: tint-color($pink, 60%) !default;
$pink-300: tint-color($pink, 40%) !default;
$red-100: tint-color($red, 80%) !default;
$red-200: tint-color($red, 60%) !default;
$red-300: tint-color($red, 40%) !default;
$orange-100: tint-color($orange, 80%) !default;
$orange-200: tint-color($orange, 60%) !default;
$orange-300: tint-color($orange, 40%) !default;
$yellow-100: tint-color($yellow, 80%) !default;
$yellow-200: tint-color($yellow, 60%) !default;
$yellow-300: tint-color($yellow, 40%) !default;
$green-100: tint-color($green, 80%) !default;
$green-200: tint-color($green, 60%) !default;
$green-300: tint-color($green, 40%) !default;
$teal-100: tint-color($teal, 80%) !default;
$teal-200: tint-color($teal, 60%) !default;
$teal-300: tint-color($teal, 40%) !default;
$cyan-100: tint-color($cyan, 80%) !default;
$cyan-200: tint-color($cyan, 60%) !default;
$cyan-300: tint-color($cyan, 40%) !default;
$grays: (
  "000": $gray-000,
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
)
!default; // themes---------
$theme-colors: (
  "primary"     : $primary,
  "primary-10"  : tint-color($primary, 10%),
  "primary-20"  : tint-color($primary, 20%),
  "primary-30"  : tint-color($primary, 30%),
  "primary-40"  : tint-color($primary, 40%),
  "primary-50"  : tint-color($primary, 50%),
  "primary-60"  : tint-color($primary, 60%),
  "primary-70"  : tint-color($primary, 70%),
  "primary-80"  : tint-color($primary, 80%),
  "primary-90"  : tint-color($primary, 90%),
  "secondary"   : $secondary,
  "secondary-10": tint-color($secondary, 10%),
  "secondary-20": tint-color($secondary, 20%),
  "secondary-30": tint-color($secondary, 30%),
  "secondary-40": tint-color($secondary, 40%),
  "secondary-50": tint-color($secondary, 50%),
  "secondary-60": tint-color($secondary, 60%),
  "secondary-70": tint-color($secondary, 70%),
  "secondary-80": tint-color($secondary, 80%),
  "secondary-90": tint-color($secondary, 90%),
  "success"     : $success,
  "success-10"  : tint-color($success, 10%),
  "success-20"  : tint-color($success, 20%),
  "success-30"  : tint-color($success, 30%),
  "success-40"  : tint-color($success, 40%),
  "success-50"  : tint-color($success, 50%),
  "success-60"  : tint-color($success, 60%),
  "success-70"  : tint-color($success, 70%),
  "success-80"  : tint-color($success, 80%),
  "success-90"  : tint-color($success, 90%),
  "info"        : $info,
  "info-10"     : tint-color($info, 10%),
  "info-20"     : tint-color($info, 20%),
  "info-30"     : tint-color($info, 30%),
  "info-40"     : tint-color($info, 40%),
  "info-50"     : tint-color($info, 50%),
  "info-60"     : tint-color($info, 60%),
  "info-70"     : tint-color($info, 70%),
  "info-80"     : tint-color($info, 80%),
  "info-90"     : tint-color($info, 90%),
  "warning"     : $warning,
  "warning-10"  : tint-color($warning, 10%),
  "warning-20"  : tint-color($warning, 20%),
  "warning-30"  : tint-color($warning, 30%),
  "warning-40"  : tint-color($warning, 40%),
  "warning-50"  : tint-color($warning, 50%),
  "warning-60"  : tint-color($warning, 60%),
  "warning-70"  : tint-color($warning, 70%),
  "warning-80"  : tint-color($warning, 80%),
  "warning-90"  : tint-color($warning, 90%),
  "danger"      : $danger,
  "danger-10"   : tint-color($danger, 10%),
  "danger-20"   : tint-color($danger, 20%),
  "danger-30"   : tint-color($danger, 30%),
  "danger-40"   : tint-color($danger, 40%),
  "danger-50"   : tint-color($danger, 50%),
  "danger-60"   : tint-color($danger, 60%),
  "danger-70"   : tint-color($danger, 70%),
  "danger-80"   : tint-color($danger, 80%),
  "danger-90"   : tint-color($danger, 90%),
  "light"       : $light,
  "light-10"    : tint-color($light, 10%),
  "light-20"    : tint-color($light, 20%),
  "light-30"    : tint-color($light, 30%),
  "light-40"    : tint-color($light, 40%),
  "light-50"    : tint-color($light, 50%),
  "light-60"    : tint-color($light, 60%),
  "light-70"    : tint-color($light, 70%),
  "light-80"    : tint-color($light, 80%),
  "light-90"    : tint-color($light, 90%),
  "dark"        : $dark,
  "dark-10"     : tint-color($dark, 10%),
  "dark-20"     : tint-color($dark, 20%),
  "dark-30"     : tint-color($dark, 30%),
  "dark-40"     : tint-color($dark, 40%),
  "dark-50"     : tint-color($dark, 50%),
  "dark-60"     : tint-color($dark, 60%),
  "dark-70"     : tint-color($dark, 70%),
  "dark-80"     : tint-color($dark, 80%),
  "dark-90"     : tint-color($dark, 90%),
  "green-1"     : $green,
  "green-2"     : $green-200,
  "green-3"     : $green-300,
  "yellow-1"    : $yellow,
  "yellow-2"    : $yellow-200,
  "yellow-3"    : $yellow-300,
  "red-1"       : $red,
  "red-2"       : $red-200,
  "red-3"       : $red-300,
  "indigo-1"    : $indigo,
  "indigo-2"    : $indigo-200,
  "indigo-3"    : $indigo-300,
  "cyan-1"      : $cyan,
  "cyan-2"      : $cyan-200,
  "cyan-3"      : $cyan-300,
  "orange-1"    : $orange,
  "orange-2"    : $orange-200,
  "orange-3"    : $orange-300,
  "purple-1"    : $purple,
  "purple-2"    : $purple-200,
  "purple-3"    : $purple-300,
  "pink-1"      : $pink,
  "pink-2"      : $pink-200,
  "pink-3"      : $pink-300,
  "blue-1"      : $blue,
  "blue-2"      : $blue-200,
  "blue-3"      : $blue-300,
  "grise-1"     : $gray-000,
  "grise-2"     : $gray-200,
  "grise-3"     : $gray-500,
  "grise-100"   : $gray-100
)
!default; // scss-docs-start gray-colors-map