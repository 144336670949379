.accordion {
  .accordion-item {
    .accordion-body {
      padding: 0 0.25rem;
    }

    .accordion-header {
      padding-top: 0;

      button {
        box-shadow: none;
        padding   : 0.5rem 1rem;
        background: var(--bs-info-90);
      }

      button[disabled] {
        background: var(--bs-grise-1);
      }
    }
  }
}