button.nuagike-button {
  font-size  : 0.9rem;
  line-height: 1rem;
  opacity    : 0.8;
  box-shadow : rgba(0, 0, 0, 0.1) 0 1px 1px 0px, rgb(119 141 169 / 46%) 0 0 1px 0;

  &.nuagike-button-float {
    height    : auto;
    width     : auto;
    transition: all 0.5s ease;
    box-shadow: 0 8px 12px -4px rgb(148 187 63 / 25%), 0 0 12px 0 rgb(148 187 63 / 46%);
    position  : fixed;
    right     : 5rem;
    bottom    : 5rem;
    z-index   : 9999;
    padding   : 0.9rem 0.6rem;
  }

  &.btn-nuagike-size-1x {
    width      : auto;
    line-height: 1rem;

    .nuagike-button-icon {
      width    : 25px;
      min-width: 25px;
    }
  }

  &.btn-nuagike-size-2x {
    width      : auto;
    line-height: 1.2rem;

    .nuagike-button-icon {
      width    : 50px;
      min-width: 50px;
    }
  }

  &.nuagike-button-circle {}

  &.nuagike-button-carret {
    &.btn-nuagike-size-1x {
      width : 25px;
      height: 25px;
    }

    &.btn-nuagike-size-2x {
      width : 40px;
      height: 40px;
    }
  }

  .nuagike-button-smallicon {
    height: 15px;
    width : 15px;
  }

  .nuagike-button-icon {
    background: rgba(0,0,0,0.2);
    height    : 25px;
    width     : 25px;

    svg {
      opacity: 1;
      height : 0.8em;
    }
  }

  .nuagike-button-text {
    padding-left: 10px;
  }

  &:hover {
    opacity   : 1;
    transition: all 0.5s ease;
  }

  &:active {
    opacity   : 0.3;
    transition: all 0.2s ease;
  }

  &.nuagike-button-circle {
    height    : 2.2rem;
    min-width : 2.2rem;
    width     : 2.2rem;
    transition: all 0.5s ease;
  }

  &.btn-nuagike-inherit {
    background: inherit;
    color     : var(--bs-info-10);
  }

  &.btn-nuagike-success {
    background      : var(--bs-success-10);
    background-image: var(--bs-gradient);
    color           : var(--bs-success-90);
  }

  &.btn-nuagike-success-30 {
    background      : var(--bs-success-30);
    background-image: var(--bs-gradient);
    color           : var(--bs-success-90);
  }

  &.btn-nuagike-success-70 {
    background      : var(--bs-success-70);
    background-image: var(--bs-gradient);
    color           : var(--bs-success-90);
  }

  &.btn-nuagike-download {
    background: var(--bs-success-10);
    color     : var(--bs-light-90);
    opacity   : 0.7;

    &:hover {
      opacity: 1;
    }
  }

  &.btn-nuagike-info {
    background      : var(--bs-info-20);
    color           : var(--bs-info-90);
    background-image: var(--bs-gradient);
  }

  &.btn-nuagike-warning {
    background      : var(--bs-warning-10);
    color           : var(--bs-warning-90);
    background-image: var(--bs-gradient);
  }

  &.btn-nuagike-red-3 {
    background      : var(--bs-red-3);
    color           : var(--bs-grise-1);
    background-image: var(--bs-gradient);
  }

  &.btn-nuagike-danger {
    background      : var(--bs-danger);
    color           : var(--bs-grise-1);
    background-image: var(--bs-gradient);
  }

  &.btn-nuagike-danger-30 {
    background: var(--bs-danger-30);
    color     : var(--bs-light);
  }

  &.btn-nuagike-light {
    background      : var(--bs-light);
    color           : var(--bs-dark);
    background-image: var(--bs-gradient);
  }

  &.btn-nuagike-light-10 {
    background      : var(--bs-light-10);
    color           : var(--bs-light-90);
    background-image: var(--bs-gradient);
  }

  &.btn-nuagike-light-90 {
    background      : var(--bs-light-90);
    color           : var(--bs-light-10);
    background-image: var(--bs-gradient);
  }

  &.btn-nuagike-dark {
    background      : var(--bs-dark);
    color           : var(--bs-light);
    background-image: var(--bs-gradient);
  }

  &.btn-nuagike-primary {
    background: var(--bs-primary);
    color     : var(--bs-light);
  }

  &.btn-nuagike-secondary {
    background: var(--bs-secondary);
    color     : var(--bs-light);
  }

  &.btn-nuagike-grise-1 {
    background: var(--bs-grise-1);
    color     : var(--bs-grise-3);
  }

  &.btn-nuagike-grise-2 {
    background: var(--bs-grise-2);
    color     : var(--bs-dark);
  }

  &.btn-nuagike-blind {
    background: var(--bs-light-50);
    color     : var(--bs-blue-1);

    &:hover {
      background: var(--bs-blue-1);
      color     : var(--bs-light-90);
    }
  }

  .spinner-border {
    width : 1.2rem;
    height: 1.2rem;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}