// stylelint-disable value-keyword-case
$font-family-sans-serif: "Titillium Web",system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace : SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; // stylelint-enable value-keyword-case
// $font-size-base affects the font size of the body text
$font-size-root        : null;
$font-size-base        : 1rem; // Assumes the browser default, typically `16px`
$font-size-sm          : $font-size-base * .775;
$font-size-lg          : $font-size-base * 1.25;
$font-weight-lighter   : lighter;
$font-weight-light     : 300;
$font-weight-normal    : 450;
$font-weight-bold      : 700;
$font-weight-bolder    : bolder;
$font-weight-base      : $font-weight-normal;
$line-height-base      : 1.5;
$line-height-sm        : 1.25;
$line-height-lg        : 2;
$h1-font-size          : $font-size-base * 1.6 !default;
$h2-font-size          : $font-size-base * 1.4 !default;
$h3-font-size          : $font-size-base * 1.2 !default;
$h4-font-size          : $font-size-base * 1.1 !default;
$h5-font-size          : $font-size-base;
$h6-font-size          : $font-size-base * 0.9; //
$headings-color        : $gray-200;
$small-font-size       : 0.675em !default;