.nuagike-pdf {
  width: 100% !important;

  .react-pdf__Document {
    width  : 100% !important;
    z-index: 1 !important;

    .react-pdf__Page {
      width: 100% !important;

      canvas {
        width  : 100% !important;
        height : auto !important;
        z-index: 1 !important;
      }

      .react-pdf__Page__textContent {
        display: none !important;
      }
    }
  }
}